<script>
	import { onMount } from 'svelte';
	import ComponentPageHead from '$lib/components/ComponentPageHead.svelte';
	export let data;

	/**
	 * @type {typeof import('svelte').SvelteComponent}
	 */
	let Component; //TODO: NEED TO THINK ABOUT BEST APPROACH FOR THIS FOR SSR

	const pageHeadContent = {
		title: 'ABCMouse: Educational Games, Books, Puzzles & Songs for Kids & Toddlers',
		link: [
			{
				rel: 'canonical',
				href: 'https://plus.abcmouse.com/login'
			},
			{
				rel: 'alternate',
				href: 'https://plus.abcmouse.com/login',
				hreflang: 'en'
			},
			{
				rel: 'alternate',
				href: 'https://plus.abcmouse.com/login/es',
				hreflang: 'es'
			}
		],
		metaTag: [
			{
				name: 'description',
				content:
					'ABCmouse.com helps kids learn to read through phonics, and teaches lessons in math, social studies, art, music, and much more. For kids age 2 to Kindergarten.'
			},
			{
				name: 'keywords',
				content:
					'Learn, learning, learn to read, math, early learning, early childhood education, online books, phonics, printables, pbs, pbskids.com, starfall, starfall.com, funbrain, teacher'
			},
			{
				property: 'og:title',
				content: 'ABCmouse.com'
			},
			{
				property: 'og:image',
				content: 'https://img.abcmouse.com/fb/ABCmouse.jpg'
			},
			{
				property: 'og:site_name',
				content: 'ABCmouse.com'
			},
			{
				property: 'og:description',
				content:
					'ABCmouse.com helps kids learn to read through phonics, and teaches lessons in math, social studies, art, music, and much more! For kids ages 2 to 8.'
			},
			{
				property: 'og:url',
				href: 'https://plus.abcmouse.com'
			}
		]
	};

	onMount(async () => {
		// TODO: refine this
		// Home page is stored in _home
		const module = await import('./_home/+page.svelte');
		Component = module.default;
	});
</script>

{#if Component}
	<ComponentPageHead content={pageHeadContent} />
	<!-- Fallback Component is handled in the resolveCondition method -->
	<svelte:component this={Component} />
{/if}
